import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { ACCOUNT } from '../../../constants/app.constants';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  public role: number = 0;
  public menuItems = [
    { icon: '/icons/home.svg', label: 'Home', active: true, link: 'home' },
    { icon: '/icons/chat.svg', label: 'Chats', link: 'chat' },
    { icon: '/icons/discover.svg', label: 'Discover', link: 'discover' },
    { icon: '/icons/settings.svg', label: 'Settings', link: 'settings' },
    { icon: '/icons/profile.svg', label: 'My Profile', link: 'profile' },
    { icon: '/icons/map.svg', label: 'My Trials', link: 'trials' },
  ];
  constructor(
    private _authService: AuthService
  ) {
    this.role = this._authService.getAccountType();
    if (this.role == ACCOUNT.SCOUT) {
      let arr = [{ icon: '/icons/dashboard.svg', label: 'Dashboard', link: 'dashboard' }, ...this.menuItems]
      this.menuItems = arr;
    }
  }


}
