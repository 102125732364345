<header class="main-header">
  <div class="logo-container">
    <!-- <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/72a13fb1e07edbadee31b4167090eac5aba1cac51d622ad93563ba773f2f37ce?placeholderIfAbsent=true&apiKey=37fbc956f06d49069b199c814c87453c" alt="Menu icon" class="menu-icon"> -->
    <img (click)="navigateTo('home')" src="/images/g2g-logo.png" alt="Company logo" class="company-logo">
  </div>
  <app-g2g-searchbar></app-g2g-searchbar>
  <div class="user-actions">
    <button (click)="showNotifications($event)" class="notification-button" aria-label="Notifications">
      @if(notifications && notifications.length) {
      <p-badge severity="danger" class="notification-count" [value]="notifications.length" />
      }
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/23b53217f5fecf8e9fa5c21971080406b1cecbd984c58e075a824e600a286985?placeholderIfAbsent=true&apiKey=37fbc956f06d49069b199c814c87453c"
        alt="" class="notification-icon">
    </button>

    <p-confirmPopup #confirmPopupRef>
      <ng-template pTemplate="headless" let-message let-test>
        <div class="border-round p-3 notifications-container">
          <span class="d-flex align-items-center justify-content-between">
            <h5 class="font-bold text-left">Notifications</h5>
            <p (click)="clearAllNotifications()" class="m-0 pointer-cursor text-gold">Clear All</p>
          </span>
          @if(notifications && notifications.length) {
          <span class="notification-items-container d-flex flex-column">
            @for(notification of notifications; track $index) {
            <table (click)="notification?.type?.id == 3 && navigateTo('trials')" border="0" class="w-100 pointer-cursor">
              <tr>
                <td>
                  <span class="font-bold">{{notification?.sender?.name}}</span>
                  @if(notification?.type?.id == 3) {
                    invited you for a trial! <span class="mx-1 text-gold pi pi-arrow-up-right"></span>
                  } @else {
                    started following you!
                  }
                </td>
                <td class="text-center" rowspan="2"><span
                    (click)="$event.preventDefault();$event.stopPropagation();dismissNotification(notification?.id)"
                    class="dismiss-notification pi pi-times"></span></td>
              </tr>
              <tr>
                <td>
                  <small class="text-dim">
                    {{notification?.createdOn | ago}}
                  </small>
                </td>
              </tr>
            </table>
            }
          </span>
          } @else {
          <span class="text-center my-3">{{ message.message }}</span>
          }
        </div>
      </ng-template>
    </p-confirmPopup>

    <div class="user-profile-section d-flex align-items-center gap-3">
      <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
        <button ngbDropdownToggle class="profile-button" aria-label="User profile">
          <app-g2g-avatar [styleClass]="'border-gold rounded-circle'" [initials]="getInitials()"
            [image]="profilePicture" [interactive]="false" size="normal"></app-g2g-avatar>
          <div class="d-flex flex-column">
            <div class="text-light">
              {{name}}
            </div>
            <small class="text-uppercase text-gold">{{(role == 2)?'Scout':'Player'}}</small>
          </div>
          <!-- <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e70f4e1c544cb6bbdbe7419df566fecd88fb8ccfb46c47727e67e96eb5f4e3b5?placeholderIfAbsent=true&apiKey=37fbc956f06d49069b199c814c87453c"
            alt="User avatar" class="profile-avatar"> -->
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
          @if(role == 3) {
          <button (click)="switchUser()" ngbDropdownItem>Switch Profile</button>
          }
          <button (click)="navigateTo('settings')" ngbDropdownItem>Settings</button>
          <button (click)="navigateTo('profile')" ngbDropdownItem>Profile</button>
          <button (click)="logout()" ngbDropdownItem>Logout</button>
        </div>
      </div>
    </div>
  </div>
</header>