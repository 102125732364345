import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';
import { AuthService } from '../../../services/auth.service';
import { G2gAvatarComponent } from '../../form/g2g-avatar/g2g-avatar.component';
import { ConfirmationService } from 'primeng/api';
import { PrimeModule } from '../../../modules/primeng.module';
import { G2gSearchbarComponent } from '../../core/g2g-searchbar/g2g-searchbar.component';
import { SharedModule } from '../../../modules/shared/shared.module';
import { UserService } from '../../../services/user.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    FormsModule,
    NgbDropdownModule,
    G2gAvatarComponent,
    PrimeModule,
    G2gSearchbarComponent,
    SharedModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [ConfirmationService]
})
export class HeaderComponent implements OnInit, OnDestroy {
  role = 0;
  profilePicture = '';
  name: string = '';
  @Input('notifications') notifications: any[] = [];
  private $unsubscribe = new Subject<void>();
  public interval: any;

  constructor(
    private _storageService: StorageService,
    private _authService: AuthService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private _userService: UserService
  ) { }

  ngOnInit(): void {
    this.role = this._authService.getAccountType();
    this.profilePicture = this._authService.$user.value?.profilePicture || '';
    this.getAllNotifications();
    this.interval = setInterval(() => {
      this.getAllNotifications();
    }, 10000);
  }

  logout = () => {
    this._storageService.clearAll();
    this._authService.isLoggedIn.next(false);
    this.router.navigate(['/']);
  }

  switchUser() {
    this.router.navigate(['switch-user']);
  }

  getInitials() {
    this.name = this._authService.$user.value?.userName || '';
    if (this.name) {
      return this.name.split(' ').map(a => a[0]).join("")
    } else {
      return '';
    }
  }

  showNotifications(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'No New Notifications'
    });
  }
  clearAllNotifications() {
    this._userService.clearAllNotifications()
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((res) => {
        this.getAllNotifications();
      });
  }

  getAllNotifications() {
    this._authService.showLoader.next(false);
    this._userService.getAllNotifications()
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((notifications) => {
        this.notifications = notifications;
        this._authService.showLoader.next(true);
      }, err => this._authService.showLoader.next(false));
  }

  dismissNotification(id: string) {
    this._userService.clearNotification(id)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((res) => {
        this.getAllNotifications();
      });
  }
  navigateTo(url: string){
    this.router.navigate([url]);
  }
  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
    clearInterval(this.interval);
  }

}
