import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HttpMonitor } from './helpers/http.monitor';
import { ToasterComponent } from './components/core/toaster/toaster.component';
import { AuthService } from './services/auth.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { HeaderComponent } from './components/layout/header/header.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { PrimeModule } from './modules/primeng.module';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,
    ToasterComponent,
    HeaderComponent,
    SidebarComponent,
    PrimeModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService]
})
export class AppComponent implements AfterViewInit, OnInit, AfterViewChecked {
  title = 'g2g-portal';
  element: string = 'preloader';
  isMobile = false;
  isLoggedIn = false;
  isOnRestrictedPage = false;
  showLoader = true;
  restrictedPages = ['/login', '/setup', '/switch-user'];

  @HostListener('window:resize', ['$event']) onResize = () => this.isMobile = window.innerWidth < 900;

  constructor(
    public httpMonitor: HttpMonitor,
    private _authService: AuthService,
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private cdref: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        if (this.restrictedPages.toString().includes(data.urlAfterRedirects)) {
          this.isOnRestrictedPage = true;
        } else {
          this.isOnRestrictedPage = false;
        }
      }
    });
    this.isMobile = window.innerWidth < 900;
    this._authService.isLoggedIn.subscribe((value) => {
      this.isLoggedIn = value;
    });
    this._authService.showLoader.subscribe((value) => {
      this.showLoader = value
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      document.getElementById(this.element)?.classList.add('hidden');
    }, 2000);
  }

  ngAfterViewChecked(): void {
    this.cdref.detectChanges();
  }

}
